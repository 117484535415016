import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import KnowledgeDetail from 'components/Contentful/KnowledgeDetail'
import Button from 'components/Button'
import Markdown from 'components/Markdown'
import contentfulImage from 'lib/contentful-image'

const Knowledge = props => {
  const imageUrl = props.image
    ? contentfulImage(props.image.url, {
        width: props.imageInBackground ? 1000 : 500,
        format: props.imageInBackground ? 'jpg' : 'png',
        quality: 80,
      })
    : null

  const link = props.link ? (
    <p>
      <Button className="btn--primary" link={props.link}>
        {props.link.label}
      </Button>
    </p>
  ) : null

  const style = props.imageInBackground
    ? {
        backgroundImage: `url(${imageUrl})`,
        color: 'white',
        backgroundSize: 'cover',
        padding: '2rem 5%',
      }
    : {}

  return (
    <div className="section section--padded-big">
      <div className="section__inner tc" style={style}>
        <h2 className="headline">{props.title}</h2>
        <Markdown className="column--measure-medium center">
          {props.description}
        </Markdown>
        <div className="knowledge-box">
          <div className="knowledge-details">
            {props.details.slice(0, 2).map(detail => {
              return (
                <KnowledgeDetail
                  key={detail.id}
                  description={detail.description}
                />
              )
            })}
          </div>
          <div className="knowledge-img">
            {!props.imageInBackground && props.image ? (
              <img src={imageUrl} alt={props.image.description} />
            ) : null}
          </div>
          <div className="knowledge-details">
            {props.details.slice(2, 4).map(detail => {
              return (
                <KnowledgeDetail
                  key={detail.id}
                  description={detail.description}
                />
              )
            })}
          </div>
        </div>
        {link}
      </div>
    </div>
  )
}

Knowledge.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.shape(KnowledgeDetail.propTypes)),
  link: linkPropType,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  imageInBackground: PropTypes.bool,
}

export default Knowledge
