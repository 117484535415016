import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'components/Markdown'

const KnowledgeDetail = props => (
  <Markdown className="knowledge-details__item">{props.description}</Markdown>
)

KnowledgeDetail.propTypes = {
  description: PropTypes.string,
}

export default KnowledgeDetail
